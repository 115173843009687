<template>
  <div>
<!--    <el-form inline class="mb-20">-->
<!--      <el-form-item label="发布人姓名">-->
<!--        <el-input placeholder="请输入发布人姓名"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="发布人电话">-->
<!--        <el-input placeholder="请输入发布人姓名"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="接单人姓名">-->
<!--        <el-input placeholder="请输入接单人姓名"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="接单人电话">-->
<!--        <el-input placeholder="请输入接单人姓名"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <el-button type="primary">搜索</el-button>-->
<!--        <el-button>重置</el-button>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="article_id" label="ID"/>
<!--      <el-table-column align="center" prop="demand" label="需求"/>-->
      <el-table-column align="center" prop="take_address" label="取件地址"/>
      <el-table-column align="center" prop="target_address" label="送达地址"/>
      <el-table-column align="center" prop="user.nickName" label="发布人"/>
<!--      <el-table-column align="center" prop="publisherPhone" label="发布人电话"/>-->
      <el-table-column align="center" prop="receiver.nickName" label="接单人">
        <template #default="{row}">
          {{ row.receiver_id ? row.receiver.nickName : '' }}
        </template>
      </el-table-column>
<!--      <el-table-column align="center" prop="runnerPhone" label="接单人电话"/>-->
      <el-table-column align="center" prop="status.text" label="状态"/>
      <el-table-column align="center" prop="create_time" label="发布时间"/>
      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :current-page="params.page"
          @current-change="changePage"
          layout="prev, pager, next"

          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="贴子详情"
      width="700px"
      top="5vh"
    >
      <el-descriptions border :column="1">
<!--        <el-descriptions-item label="需求">实验仪器</el-descriptions-item>-->
        <el-descriptions-item label="取件地址">{{detail.take_address}}</el-descriptions-item>
        <el-descriptions-item label="送达地址">{{detail.target_address}}</el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.user.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布人电话">{{detail.user.phone}}</el-descriptions-item>
        <el-descriptions-item label="接单人">{{detail.receiver_id ? detail.receiver.nickName : ''}}</el-descriptions-item>
        <el-descriptions-item label="接单人电话">{{detail.receiver_id ? detail.receiver.phone : ''}}</el-descriptions-item>
        <el-descriptions-item label="跑腿费">{{detail.price}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{detail.status.text}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: 'Errand',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      detail: null,
    };
  },
  created() {
    this.getList(1)
  },
  methods: {
    showDetailBox(data) {
      this.detail = data;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.articleLegwork.page({
        page: page || this.params.page,
      }).then((res) => {
        // console.log(res);
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    del(data) {
      let that = this;
      this.$api.article.delete({
        article_id: data.article_id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    submit() {
      this.getList(1)
    },
    reset() {
      this.params.search = '';
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
  },
};
</script>

<style scoped>
:deep(.el-descriptions__label) {
  min-width: 90px;
}
</style>
